export default [
    { header: "router.admin.admin" },
    {
        icon: 'mdi-file',
        title: "router.admin.languages",
        to: { name: 'admin.languages'},
    },
    {
        icon: 'mdi-file',
        title: "router.admin.countries",
        to: { name: 'admin.countries'},
    },
    {
        icon: 'mdi-file',
        title: "router.admin.users",
        to: { name: 'admin.users'},
    },
    {
        icon: 'mdi-file',
        title: "router.admin.offers",
        to: { name: 'admin.offers'},
    },
    {
        icon: 'mdi-file',
        title: "router.admin.landingDesigns",
        to: { name: 'admin.landingDesigns'},
    },
    {
        icon: 'mdi-file',
        title: "router.admin.landings",
        to: { name: 'admin.landings'},
    },
    {
        icon: 'mdi-file',
        title: "router.admin.cpaNetworks",
        to: { name: 'admin.cpaNetworks'},
    },
    {
        icon: 'mdi-file',
        title: "router.admin.links",
        to: { name: 'admin.links'},
    },
    {
        icon: 'mdi-file',
        title: "router.admin.domains",
        to: { name: 'admin.domains'},
    },
    {
        icon: 'mdi-file',
        title: "router.admin.plans",
        to: { name: 'admin.plans'},
    },
    {
        icon: 'mdi-file',
        title: "router.admin.promoCodes",
        to: { name: 'admin.promoCodes'},
    },
    {
        icon: 'mdi-file',
        title: "router.admin.transactions",
        to: { name: 'admin.transactions'},
    },
    {
        icon: 'mdi-file',
        title: "router.admin.masters",
        to: { name: 'admin.masters'},
    },
]
